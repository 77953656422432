import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useStats } from "react-instantsearch";
import { ContactButtonIcon } from "./ContactButtonIcon";
import IntlMessageFormat from "intl-messageformat";
import { SEARCH_REDIRECT_URL } from "./constants";
export function ContactButton({ className = "" }) {
    const { query, nbHits } = useStats();
    // Format the message based on the number of hits
    const message = new IntlMessageFormat(`{nbHits, plural,
        =0 {}
        =1 {Supplier found}
        other {Suppliers found}
      }`, "en-US").format({ nbHits: nbHits });
    // Redirect to onboarding page with query and count
    const handleClick = () => {
        const count = query ? nbHits.toString() : "";
        const queryParams = new URLSearchParams({
            query: query,
            count: count,
        });
        window.location.assign(`${SEARCH_REDIRECT_URL}?${queryParams}`);
    };
    return (_jsx("div", { className: className, children: _jsxs("div", { className: "w-full md:w-auto self-center text-center mb-2", children: [query && nbHits > 0 ? (_jsxs("span", { className: "text-sm mb-2 block h-6 text-[#2267F6]", children: [_jsx("span", { className: "font-bold", children: nbHits.toLocaleString() }), " ", message] })) : (_jsx("span", { className: "text-transparent text-sm mb-2 block h-6 text-[#2267F6]", children: "999,999 Suppliers found" })), _jsxs("button", { className: "w-full md:w-auto bg-white text-[#2267F6] px-6 py-3 rounded-2xl border-2 border-[#2267F6] flex items-center justify-center font-bold transition-transform duration-300 transform hover:scale-105", onClick: handleClick, children: [_jsx("span", { className: "block", children: _jsx(ContactButtonIcon, {}) }), _jsxs("span", { className: "text-center md:text-left leading-tight pl-4", children: ["Contact ", _jsx("br", { className: "hidden md:inline-block" }), " them all"] })] })] }) }));
}
