import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import { App } from "../components/search/App";
function isSearchEnabled() {
    // const params = new URLSearchParams(window.location.search);
    // return params.has('search');
    // search is live and no longer behind an FE feature toggle
    // this code can be cleaned up later
    return true;
}
if (isSearchEnabled()) {
    const container = document.getElementById("search-root");
    if (container) {
        const root = createRoot(container);
        root.render(_jsx(App, {}));
    }
}
